@import '~uikit';
@import '_vars';
@import '_font';

#flash-msg {
  background-color: $black;
  padding: 50px 0;
  font-size: 80px;
  font-weight: 900;
  letter-spacing: 1px;
  overflow: hidden;
  margin-bottom: 80px;
  span {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: $white;
    width: var(--tw);
    text-shadow: var(--tw) 0 currentColor,
    calc(var(--tw) * 2) 0 currentColor,
    calc(var(--tw) * 3) 0 currentColor,
    calc(var(--tw) * 4) 0 currentColor,
    calc(var(--tw) * 5) 0 currentColor,
    calc(var(--tw) * 6) 0 currentColor,
    calc(var(--tw) * 7) 0 currentColor,
    calc(var(--tw) * 8) 0 currentColor,
    calc(var(--tw) * 9) 0 currentColor,
    calc(var(--tw) * 10) 0 currentColor,
    calc(var(--tw) * 11) 0 currentColor,
    calc(var(--tw) * 12) 0 currentColor,
    calc(var(--tw) * 13) 0 currentColor,
    calc(var(--tw) * 14) 0 currentColor,
    calc(var(--tw) * 15) 0 currentColor;
    will-change: transform;
    animation: marquee var(--ad) linear infinite;
    animation-play-state: running;
  }
  i {
    font-size: 40px;
    padding-left: 20px;
  }
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}


@media only screen and (max-width: 1250px) {

  .home-section .section-titles {
    h3 {
      font-size: 20px;
    }
    a {
      i {
        font-size: 60px;
      }
    }
  }

}

@media only screen and (max-width: 640px) {

  .home-section {
    margin-bottom: 50px;

    .section-titles {

      h2 {
        margin-bottom: 0;
      }

      h3 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      a {
        font-size: 16px;

        i {
          font-size: 50px;
        }
      }
    }
  }

  #flash-msg {
    padding: 20px 0;
    font-size: 53px;
    margin-bottom: 30px;
  }

}